.Popup {
  position: relative;

  .Popup-box {
    background-color: var(--grey-super-dark);
    position: absolute;
    top: 8px;
    left: 8px;
    padding: 8px 16px;
    box-shadow: 0px 8px 10px #0000004d;
  }
}
