.Profile {
  .Profile-title {
    position: relative;
    margin-bottom: 32px;
    display: flex;
    align-items: center;

    .Profile-title-info {
      position: absolute;
      right: 0;
      background-color: var(--white);
      border: 1px solid var(--grey-medium-light);
      border-radius: 4px;
      padding: 6px 12px;
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      align-items: flex-end;
    }
  }

  .Profile-blocks {
    display: grid;
    grid: auto / 1fr 1fr;
    grid-column-gap: 32px;

    > div > div {
      margin-bottom: 24px;
    }
  }

  .Profile-block-title {
    height: 56px;
    border-bottom: 1px solid var(--grey-medium-light);
    display: flex;
    align-items: center;
    padding: 0 24px;
  }

  .Profile-block-tags {
    display: flex;
    padding: 24px;
  
    > * {
      margin-right: 4px;
    }
  }

  .Profile-block-clients {
    padding: 24px;
  }

  .Profile-password-submit {
    svg circle {
      stroke: white;
    }
  }
}
