.Website {
  .Website-title {
    position: relative;
    margin-bottom: 32px;
    display: flex;
    align-items: center;

    .Website-title-info {
      position: absolute;
      right: 0;
      background-color: var(--white);
      border: 1px solid var(--grey-medium-light);
      border-radius: 4px;
      padding: 6px 12px;
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      align-items: flex-end;
    }
  }

  .Website-blocks {
    display: grid;
    grid: auto / 1fr 1fr;
    grid-column-gap: 32px;

    > div > div {
      margin-bottom: 24px;
    }
  }
}
