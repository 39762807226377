.Content {
  flex: 1;
  max-height: 100vh;
  overflow: auto;

  .Content-overflow {
    display: flex;
    padding: 64px 72px;
    min-width: calc(1150px - 96px);
    min-height: calc(100vh - 128px);

    > * {
        flex: 1;
    }
  }
}
