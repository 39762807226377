.Login-root {
  padding: 24px 32px;

  .Login-title {
    margin: 8px 0 24px;
  }

  form {
    display: flex;
    flex-flow: column;

    > :not(:first-child) {
      margin-top: 16px;
    }
  }

  .Login-footer {
    display: flex;
    justify-content: center;
    margin-top: 24px;
  }
}
