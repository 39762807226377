html, button, input, textarea, .inter {
  font-family: 'Inter', sans-serif;
}

@supports (font-variation-settings: normal) {
  html, button, input, textarea, .inter { font-family: 'Inter var', sans-serif; }
}

body {
  background-color: var(--grey-light);
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.shadow-8 {
  box-shadow: 0px 8px 10px var(--shadow);
}

.shadow-10 {
  box-shadow: 0px 10px 10px var(--shadow);
}

.shadow-12 {
  box-shadow: 0px 12px 10px var(--shadow);
}

:root {
  --primary-super-light: #FCEDEB;
  --primary-light: #FCDBD7;
  --primary-medium-light: #F26755;
  --primary: #E23117;
  --secondary-super-light: #E8F0FA;
  --secondary-light: #A8C0DE;
  --secondary-medium-light: #587BA7;
  --secondary: #1D3B61;
  --white: #FFFFFF;
  --grey-super-light: #FCFCFC;
  --grey-light: #F8F8F8;
  --grey-medium-light: #EDEDED;
  --grey-medium: #D6D6D6;
  --grey-medium-dark: #A7A7A7;
  --grey-dark: #707070;
  --grey-super-dark: #414141;
  --error: #E23117;
  --warning-super-light: #F7F4ED;
  --warning-light: #F8EED7;
  --warning: #F7B734;
  --warning-dark: #DD9400;
  --success: #359089;
  --shadow: #44447B1A;
  --text: #38373B;
  --orange: #FF9D00;
}
