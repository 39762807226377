.CenterContent-root {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

  .CenterContent-box {
    box-shadow: 0px 4px 20px var(--shadow);
    background-color: var(--white);
    border-radius: 4px;
    margin: 92px 0;
  }

  .CenterContent-cactus {
    z-index: -1;
    position: absolute;
    right: -192px;
    bottom: -48px;
  }

  .CenterContent-logo {
    position: absolute;
    bottom: 88px;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: -1;
  }
}
