.NotFound {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    > :first-child {
        margin-bottom: 24px;
    }
}
