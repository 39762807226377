.Loader {
  animation: spin-animation 2s cubic-bezier(0.48, 0.1, 0.31, 0.9) infinite;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes spin-animation {
  from {
    transform: rotate(-360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
