.Snackbar {
  display: flex;
  position: absolute;

  &.Snackbar-column {
    flex-direction: column;

    .SnackbarItem:not(:first-child) {
      margin-top: 16px;
    }
  }

  &.Snackbar-column-reverse {
    flex-direction: column-reverse;

    .SnackbarItem:not(:first-child) {
      margin-bottom: 16px;
    }
  }

  .SnackbarItem {
    min-height: 48px;
    width: 400px;
    padding-right: 6px;
    display: flex;
    align-items: center;
    transition: opacity 0.5s linear;

    &.SnackbarItem-expired {
      opacity: 0;
    }

    .SnackbarItem-close {
      width: 32px;
      height: 32px;
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      border-radius: 4px;
      cursor: pointer;
    }

    > :first-child {
      margin-left: 16px;
    }

    .SnackbarItem-message {
      flex: 1;

      &:not(:first-child) {
        margin-left: 16px;
      }
    }

    &.SnackbarItem-info {
      background-color: var(--grey-super-dark);

      svg {
        fill: var(--white);
      }

      .SnackbarItem-message {
        color: var(--white);
      }

      .SnackbarItem-close:hover {
        background-color: var(--grey-dark);
      }
    }

    &.SnackbarItem-error {
      background-color: var(--error);

      svg {
        fill: var(--white);
      }

      .SnackbarItem-message {
        color: var(--white);
      }

      .SnackbarItem-close:hover {
        background-color: var(--primary-medium-light);
      }
    }
  }
}
