.Tag-url {
  text-decoration: unset;
}

.Tag {
  border-radius: 14px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;

  &.size-extra-small {
    height: 16px;
    &.Tag-circle {
      width: 16px;
      padding: 0;
    }
  }

  &.size-small {
    height: 20px;
    &.Tag-circle {
      width: 20px;
      padding: 0;
    }
  }

  &.size-normal {
    height: 24px;
    &.Tag-circle {
      width: 24px;
      padding: 0;
    }
  }

  &.color-red {
    background-color: var(--primary);
    color: var(--white);
  }

  &.color-orange {
    background-color: var(--orange);
    color: var(--white);
  }

  &.color-green {
    background-color: var(--success);
    color: var(--white);
  }

  &.color-blue {
    background-color: var(--secondary);
    color: var(--white);
  }

  &.color-red-light {
    background-color: var(--primary-light);
    color: var(--primary);
    svg {
      fill: var(--primary);
    }
    &.url:hover {
      background-color: var(--primary-super-light);
      color: var(--primary-medium-light);
      svg {
        fill: var(--primary-medium-light);
      }
    }
  }

  &.color-yellow-light {
    background-color: var(--warning-light);
    color: var(--warning-dark);
    svg {
      fill: var(--warning-dark);
    }
    &.url:hover {
      background-color: var(--warning-super-light);
      color: var(--warning);
      svg {
        fill: var(--warning);
      }
    }
  }

  .Tag-icon {
    margin-left: 8px;
    display: flex;
    align-items: center;
  }
}
