.Website-costs-title {
  height: 56px;
  border-bottom: 1px solid var(--grey-medium-light);
  display: flex;
  align-items: center;
  padding: 0 24px;
}

.Website-costs-costs-total {
  display: flex;
  align-items: center;
  height: 64px;
  padding: 0 24px;
  border-top: 1px solid var(--grey-medium-light);

  .Website-costs-costs-total-title {
    flex: 1 1;
  }
}

.Website-costs-costs-row {
  height: 64px;
  display: flex;
  align-items: center;
  padding: 0 24px;

  &:nth-child(even) {
    background-color: var(--grey-light);
  }

  &:hover {
    background-color: var(--secondary-super-light);
  }

  .Website-costs-costs-row-service {
    flex: 1 1;
    color: var(--grey-super-dark);
    display: flex;
    align-items: center;

    .Popup {
      display: flex;
      justify-content: center;
      align-items: center;

      .Popup-box {
        min-width: 210px;
      }
    }

    > :not(:first-child) {
      margin-left: 4px;
    }
  }

  .Website-costs-costs-row-cost {
    color: var(--grey-super-dark);
  }
}
