.Pagination {
  display: flex;

  .Pagination-button {
    border: none;
    padding: 0;
    background-color: transparent;
    font-size: 12px;
    color: var(--grey-dark);
    font-weight: 400;
    border-radius: 4px;
    width: 28px;
    height: 28px;
    cursor: pointer;

    svg {
      fill: var(--grey-dark);
    }

    &:hover:not(.Pagination-button-active) {
      background-color: var(--secondary-super-light);
      color: var(--secondary-medium-light);

      svg {
        fill: var(--secondary-medium-light);
      }
    }

    &.Pagination-button-active {
      cursor: unset;
      color: var(--secondary-medium-light);
      font-weight: 700;
      text-decoration: underline;
    }
  }

  .Pagination-hidden-buttons {
    width: 56px;
  }
}
