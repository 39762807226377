.RecurringCosts {
  .RecurringCosts-title {
    position: relative;
    margin-bottom: 32px;
    display: flex;
    align-items: center;

    .RecurringCosts-title-info {
      position: absolute;
      right: 0;
      background-color: var(--white);
      border: 1px solid var(--grey-medium-light);
      border-radius: 4px;
      padding: 6px 12px;
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      align-items: flex-end;
    }
  }

  .RecurringCosts-block-title {
    display: flex;
    align-items: center;
    height: 56px;
    padding: 0 24px;
    border-bottom: 1px solid var(--grey-medium-light);

    > :first-child {
      margin-right: 16px;
    }
  }

  .RecurringCosts-top {
    margin-bottom: 32px;
  }

  .RecurringCosts-bottom {
    display: flex;

    > * {
      flex: 1;
      &:not(:first-child) {
        margin-left: 32px;
      }
    }
  }
}
