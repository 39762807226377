.ChangeClients {
  z-index: 10;
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00000070;

  .ChangeClients-box {
    width: calc(100vw - 50%);
    min-width: 400px;
    max-height: 80vh;
    background-color: var(--white);
    border-radius: 5px;
    position: relative;
    overflow-y: hidden;

    .ChangeClients-close {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 8px;
      right: 8px;

      &:hover {
        background-color: var(--grey-dark);

        svg {
          fill: var(--white);
        }
      }
    }

    .ChangeClients-title {
      padding: 16px;
      border-bottom: 1px solid var(--grey-medium-light);
    }

    .ChangeClients-body {
      padding: 0 16px 16px;

      .ChangeClients-body-text {
        margin: 8px 0;
      }

      .ChangeClients-row {
        height: 40px;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 0 16px;
        border-radius: 4px;
        margin-bottom: 8px;
        border: 1px solid var(--grey-medium-light);

        &:last-child {
          margin-bottom: 0;
        }

        &:hover {
          background-color: var(--secondary-super-light);
        }
      }

      .ChangeClients-list {
        overflow-y: auto;
        max-height: calc(80vh - 24px - 32px - 80px);
      }

    }
  }
}
