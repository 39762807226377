.Website-maintenance-title {
  height: 56px;
  border-bottom: 1px solid var(--grey-medium-light);
  display: flex;
  align-items: center;
  padding: 0 24px;
}

.Website-maintenance-tags {
  display: flex;
  padding: 24px;

  > * {
    margin-right: 4px;
  }
}
