.Table {
  .Table-header {
    display: flex;
    align-items: center;
    height: 47px;
    border-bottom: 1px solid var(--grey-medium-light);

    .Table-header-caret {
      width: 7px;
      padding: 0 24px;
    }

    .Table-head {
      display: flex;
      align-items: center;
      height: 100%;
      color: var(--grey-super-dark);
      padding-left: 8px;

      &.Table-head-right {
        flex: 1;
        display: flex;
        justify-content: flex-end;
      }

      &:first-child {
         padding-left: 24px;
      }

      &:last-child {
         padding-right: 24px;
      }

      &.Table-head-sortable {
        cursor: pointer;
        &:hover {
          background-color: var(--secondary-super-light);
          color: var(--secondary-medium-light);
          .Table-head-sort svg {
            fill: var(--secondary-medium-light);
          }
        }
      }

      .Table-head-sort {
        margin-left: 16px;
        width: 16px;
        height: 16px;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          fill: var(--grey-medium-dark);
        }

        &.Table-head-sort-active svg {
          fill: var(--grey-super-dark);
        }
      }
    }
  }

  .Table-body {
    display: flex;
    flex-flow: column;
  }
}
