.Navigation-root {
  width: 96px;
  display: flex;
  flex-flow: column;
  height: 100vh;
  background-color: var(--grey-super-light);

  .Navigation-tag-container {
    position: relative;

    .Navigation-tag {
      pointer-events: none;
      position: absolute;
      top: 8px;
      right: 16px;
    }
  }

  .Navigation-fill {
    flex: 1;
  }

  .Navigation-logo {
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 38px;
      height: 38px;
    }
  }

  .Navigation-user {
    padding-top: 16px;

    .Navigation-user-button {
      outline: none;
      padding: 8px 0;
      cursor: pointer;
      width: 100%;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      border: none;
      background-color: transparent;

      p {
        color: var(--grey-dark);
      }

      &:hover {
        background-color: var(--primary-super-light);
        p {
          color: var(--primary-medium-light);
        }
        svg {
          fill: var(--primary-medium-light);
        }
      }
    }

    &.Navigation-user-active .Navigation-user-button {
      background-color: var(--primary-light);
      p {
        color: var(--primary);
      }
      svg {
        fill: var(--primary);
      }
    }

    .Navigation-icons {
      margin-bottom: 4px;
      display: flex;

      p {
        width: 220px;
      }

      > :nth-child(2) {
        margin-left: 4px;
      }
    }

    p {
      text-align: center;
    }

    .Navigation-user-name {
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: calc(100% - 10px);
    }
  }

  .Navigation-version {
    display: flex;
    justify-content: center;
    padding: 16px 0 24px;
  }

  .Navigation-link {
    height: 56px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    padding: 16px;
    color: var(--grey-dark);
    text-align: center;

    svg {
      fill: var(--grey-medium-dark);
    }

    &:hover {
      cursor: pointer;
      background-color: var(--primary-super-light);
      color: var(--primary-medium-light);
      svg {
        fill: var(--primary-medium-light);
      }
    }

    &.Navigation-active {
      background-color: var(--primary-light);
      color: var(--primary);

      svg {
        fill: var(--primary);
      }
    }
  }
}
