.Invoices {
  .Invoices-title {
    margin-bottom: 32px;
    display: flex;
    align-items: center;

    .Invoices-title-loader {
      margin-left: 16px;
    }

    .Invoices-title-fill {
      flex: 1;
    }
    > :not(:last-child) {
      margin-right: 16px;
    }
  }

  .Invoices-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    height: 64px;
    padding: 0 24px;

    .Invoices-footer-pagination {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      pointer-events: none;

      > * {
        pointer-events: initial;
      }
    }
  }

  .Invoices-row {
    height: 64px;
    display: flex;
    align-items: center;

    &:nth-child(even) {
      background-color: var(--grey-light);
    }

    &:hover {
      background-color: var(--secondary-super-light);

      .Invoices-row-number, .Invoices-row-status, .Invoices-row-amount, .Invoices-row-date, .Invoices-row-expiration-date {
        color: var(--secondary-medium-light);
      }
    }

    &.Invoices-row-red {
      background-color: var(--primary-light);
    }

    .Invoices-row-number {
      padding-left: 24px;
      flex: 1 0 200px;
      color: var(--grey-super-dark);
    }

    .Invoices-row-status, .Invoices-row-amount, .Invoices-row-date, .Invoices-row-expiration-date {
      padding-left: 8px;
      color: var(--grey-super-dark);
      flex: 1 0 100px;
      display: flex;

      > :not(:last-child) {
        margin-right: 12px;
      }
    }

    .Invoices-row-status {
      flex: 1 0 200px;
    }

    .Invoices-row-expiration-date {
      padding-right: 24px;
    }
  }
}
