.Plugins {
  .Plugins-title {
    margin-bottom: 32px;
    display: flex;
    align-items: center;

    .Plugins-title-loader {
      margin-left: 16px;
    }

    .Plugins-title-fill {
      flex: 1;
    }
  }

  .Plugins-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    height: 64px;
    padding: 0 24px;

    .Plugins-footer-pagination {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      pointer-events: none;

      > * {
        pointer-events: initial;
      }
    }
  }

  .Plugins-row {
    &:nth-child(even) {
      background-color: var(--grey-light);
    }

    .Plugins-row-content {
      height: 64px;
      display: flex;
      align-items: center;
      cursor: pointer;

      &:hover {
        background-color: var(--secondary-super-light);

        .Plugins-row-name, .Plugins-row-cost, .Plugins-row-installations {
          color: var(--secondary-medium-light);
        }

        .Plugins-row-caret {
          fill: var(--secondary-medium-light);
        }
      }

      &.Table-row-active {
        background-color: var(--secondary);

        .Plugins-row-name, .Plugins-row-cost, .Plugins-row-installations {
          color: var(--white);
        }

        .Plugins-row-caret {
          fill: var(--white);
        }
      }

      .Plugins-row-caret {
        fill: var(--grey-dark);
        padding-left: 24px;
        padding-right: 24px;
      }

      .Plugins-row-name {
        padding-left: 8px;
        flex: 1 0 376px;
        color: var(--grey-super-dark);
      }

      .Plugins-row-cost, .Plugins-row-installations {
        padding-left: 8px;
        color: var(--grey-super-dark);
        flex: 1 0 200px;
        display: flex;
        > :not(:last-child) {
          margin-right: 12px;
        }
      }

      .Plugins-row-cost {
        padding-right: 24px;
      }
    }

    .Plugins-row-collapsed-content {

    }
  }

  .Plugins-collapsed-content {
    height: 44px;
    display: flex;
    align-items: center;
    padding-left: calc(24px + 7px + 24px);

    .Plugins-collapsed-content-website {
      flex: 2 0 576px;
      padding-left: 16px;
      text-decoration: underline;

      &:hover {
        cursor: pointer;
      }

      > * {
        padding-left: 24px;
      }
    }

    .Plugins-collapsed-content-price {
      flex: 1 0 200px;
      padding-left: 8px;
      padding-right: 24px;
      color: var(--grey-super-dark);
    }
  }
}
