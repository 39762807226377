.Website-plugins-title {
  display: flex;
  align-items: center;
  height: 56px;
  padding: 0 24px;
  border-bottom: 1px solid var(--grey-medium-light);

  > :not(:last-child) {
    margin-right: 16px;
  }

  .Website-plugins-title-fill {
    flex: 1;
  }
}

.Website-plugins-row, .Website-plugins-bundle-row {
  &:nth-child(even) {
    background-color: var(--grey-light);
  }
}

.Website-plugins-bundle-row {
  > .Website-plugins-row {
    cursor: pointer;
  }

  .Website-plugins-row-caret {
    margin-right: 24px;
    fill: var(--grey-dark);
  }

  .Website-plugins-row-box {
   fill: var(--orange);
  }

  &.Website-plugins-bundle-row-active {
    > .Website-plugins-row {
      background-color: var(--secondary);

      .Website-plugins-row-name, .Website-plugins-row-price {
        color: var(--white);
        font-weight: 700;
      }

      .Website-plugins-row-caret, .Website-plugins-row-box {
        fill: var(--white);
      }
    }
  }

  .Website-plugins-bundle-plugins {
    .Website-plugins-row-name {
      padding-left: 48px;
      flex: 1 0 276px;
    }

    .Website-plugins-row-price {
      text-decoration: line-through;
    }
  }
}

.Website-plugins-row {
  height: 64px;
  display: flex;
  align-items: center;

  &:hover {
    background-color: var(--secondary-super-light);

    .Website-plugins-row-name, .Website-plugins-row-price {
      color: var(--secondary-medium-light);
    }
  }

  .Website-plugins-row-name {
    padding-left: 24px;
    flex: 1 0 300px;
    color: var(--grey-super-dark);
    display: flex;

    .Website-plugins-row-box {
      margin-left: 8px;
    }
  }

  .Website-plugins-row-price {
    padding-left: 8px;
    flex: 1 0 60px;
    color: var(--grey-super-dark);
    text-align: right;

    p {
      padding-right: 8px;
    }
  }

  .Website-plugins-row-link {
    flex: 1 0 40px;
    padding-left: 8px;
    padding-right: 24px;

    > a {
      display: flex;
      align-items: center;
      text-decoration: none;

      > svg {
        margin-left: 8px;
      }
    }
  }
}

.Website-plugins-total {
  display: flex;
  align-items: center;
  height: 64px;
  padding: 0 24px;
  border-top: 1px solid var(--grey-medium-light);

  .Website-plugins-total-title {
    flex: 1 0 300px;
    display: flex;
    align-items: center;

    .Popup {
      display: flex;
      justify-content: center;
      align-items: center;

      .Popup-box {
        min-width: 210px;
      }
    }

    > :not(:first-child):last-child {
      margin-left: 4px;
    }
  }

  .Website-plugins-total-price {
    flex: 1 0 60px;
    text-align: right;
    padding-right: 16px;
  }

  .Website-plugins-total-fill {
    flex: 1 0 40px;
  }
}
