.RecurringCosts-credits-row {
  height: 64px;
  display: flex;
  align-items: center;

  &:nth-child(even) {
    background-color: var(--grey-light);
  }

  &:hover {
    background-color: var(--secondary-super-light);

   .RecurringCosts-credits-row-service, .RecurringCosts-credits-row-cost, .RecurringCosts-credits-row-status {
      color: var(--secondary-medium-light);
    }
  }

  .RecurringCosts-credits-row-service {
    padding-left: 24px;
    flex: 1 0 200px;
    color: var(--grey-super-dark);
  }

  .RecurringCosts-credits-row-cost, .RecurringCosts-credits-row-status {
    padding-left: 8px;
    flex: 1 0 100px;
    color: var(--grey-super-dark);
  }

  .RecurringCosts-credits-row-status {
    padding-right: 24px;
  }
}
