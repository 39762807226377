.Websites {
  .Websites-title {
    margin-bottom: 32px;
    display: flex;

    > :not(:last-child) {
      margin-right: 16px;
    }
  }

  .Websites-row {
    height: 64px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:nth-child(even) {
      background-color: var(--grey-light);
    }

    &:hover {
      background-color: var(--secondary-super-light);

      .Websites-row-url p {
        color: var(--secondary-medium-light);
      }
    }

    .Websites-row-url {
      flex: 1 0 376px;
      color: var(--grey-super-dark);
      padding-left: 24px;
    }

    .Websites-row-environments {
      padding-left: 8px;
      flex: 1 0 200px;
      display: flex;
      > :not(:last-child) {
        margin-right: 12px;
      }
    }

    .Websites-row-maintenance {
      padding-left: 8px;
      padding-right: 24px;
      flex: 1 0 200px;
      display: flex;
    }
  }
}
