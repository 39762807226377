.Domains {
  .Domains-title {
    position: relative;
    margin-bottom: 32px;
    display: flex;
    align-items: center;

    > :not(:last-child) {
      margin-right: 16px;
    }

    .Domains-title-info {
      position: absolute;
      right: 0;
      background-color: var(--white);
      border: 1px solid var(--grey-medium-light);
      border-radius: 4px;
      padding: 6px 12px;
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      align-items: flex-end;
    }
  }

  .Domains-row {
    height: 64px;
    display: flex;
    align-items: center;

    &:nth-child(even) {
      background-color: var(--grey-light);
    }

    &:hover {
      background-color: var(--secondary-super-light);

      .Domains-row-domain, .Domains-row-yearly, .Domains-row-renewal {
        color: var(--secondary-medium-light);
      }
    }

    .Domains-row-domain {
      padding-left: 24px;
      flex: 1 0 376px;
      color: var(--grey-super-dark);
      display: flex;
      overflow: hidden;

      > *:not(:last-child) {
        margin-right: 4px;
      }

      > :last-child {
        font-style: italic;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .Domains-row-renewal {
      padding-right: 24px;
    }

    .Domains-row-yearly, .Domains-row-renewal {
      padding-left: 8px;
      color: var(--grey-super-dark);
      flex: 1 0 200px;
      display: flex;

      > :not(:last-child) {
        margin-right: 12px;
      }
    }
  }

  .Domains-total {
    display: flex;
    align-items: center;
    height: 64px;
    padding: 0 24px;
    border-top: 1px solid var(--grey-medium-light);

    .Domains-total-title {
      flex: 1 0 376px;
    }

    .Domains-total-price {
      padding-left: 8px;
      flex: 1 0 200px;
    }

    .Domains-total-fill {
      padding-left: 8px;
      flex: 1 0 200px;
    }
  }
}
