.RecurringCosts-webites-total {
  display: flex;
  align-items: center;
  height: 64px;

  .RecurringCosts-webites-total-title {
    flex: 1 0 415px;
    padding-left: 24px;
  }

  .RecurringCosts-webites-total-price {
    flex: 1 0 200px;
    padding-left: 8px;
  }

  .RecurringCosts-webites-total-fill {
    flex: 1 0 200px;
    padding-left: 8px;
    padding-right: 24px;
  }
}

.RecurringCosts-websites-row {
  &:nth-child(even) {
    background-color: var(--grey-light);
  }

  .RecurringCosts-websites-row-content {
    height: 64px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      background-color: var(--secondary-super-light);
    }

    .RecurringCosts-websites-row-url {
      padding-left: 8px;
      flex: 1 0 376px;
    }

    .RecurringCosts-websites-row-cost, .RecurringCosts-websites-row-environments {
      padding-left: 8px;
      flex: 1 0 200px;
    }

    .RecurringCosts-websites-row-url, .RecurringCosts-websites-row-cost, .RecurringCosts-websites-row-environments {
      color: var(--secondary);
    }

    .RecurringCosts-websites-row-caret {
      fill: var(--grey-dark);
      padding-left: 24px;
      padding-right: 24px;
    }


    .RecurringCosts-websites-row-environments {
      padding-right: 24px;
      display: flex;

      > :not(:last-child) {
        margin-right: 12px;
      }
    }

    &.Table-row-active {
      background-color: var(--secondary);

      .RecurringCosts-websites-row-url, .RecurringCosts-websites-row-cost {
        color: var(--white);
      }

      .RecurringCosts-websites-row-caret {
        fill: var(--white);
      }
    }
  }

  .RecurringCosts-websites-collapsed-row {
    display: flex;
    align-items: center;
    height: 44px;
    padding-left: calc(24px + 7px + 24px);

    .RecurringCosts-websites-collapsed-row-name {
      flex: 1 0 376px;
      padding-left: 16px;
      position: relative;

      a {
        text-decoration-color: var(--grey-super-dark);
      }

      > div {
        padding-left: 24px;
        display: flex;

        > :not(:first-child) {
          margin-left: 4px;
        }

        .Popup {
          display: flex;
          justify-content: center;
          align-items: center;

          .Popup-box {
            width: 210px;
          }
        }
      }

      .RecurringCosts-websites-collapsed-row-name-plugincount {
        position: absolute;
        top: -8px;
        left: 92px;
      }
    }

    .RecurringCosts-websites-collapsed-row-cost, .RecurringCosts-websites-collapsed-row-fill {
      padding-left: 8px;
      flex: 1 0 200px;
    }

    .RecurringCosts-websites-collapsed-row-fill {
      padding-right: 24px;
    }
  }
}
