.Input {
  height: 40px;
  display: flex;
  position: relative;

  .Input-icon {
    width: 40px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0px;
    left: 0px;
    pointer-events: none;

    &.Input-icon-password {
      left: unset;
      right: 0;
      pointer-events: unset;
      cursor: pointer;
    }
  }

  input {
    background-color: var(--white);
    border: 1px solid var(--grey-medium-dark);
    border-radius: 4px;
    padding: 0 8px 0 40px;
    color: var(--grey-super-dark);
    font-size: 14px;
    font-weight: 400;
    flex: 1;
    outline: none;

    &:hover, &:focus {
      border-color: var(--secondary-medium-light);
    }
  }

  &.Input-password {
    input {
      padding-right: 40px;
    }
  }
}

.FormControl {
  display: flex;
  flex-flow: column;

  .Input-label {
    padding-bottom: 8px;
  }

  &.FormControl-error {
    input {
      border-color: var(--error);
    }
  }

  .FormControl-error-message {
    margin-top: 8px;
    display: flex;
    align-items: center;
    min-height: 16px;

    svg {
      margin-right: 5px;
    }
  }
}
