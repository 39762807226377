.RecurringCosts-costs-total {
  display: flex;
  align-items: center;
  height: 64px;
  padding: 0 24px;
  border-top: 1px solid var(--grey-medium-light);

  .RecurringCosts-costs-total-title {
    flex: 1;
  }
}

.RecurringCosts-costs-row {
  height: 64px;
  display: flex;
  align-items: center;
  padding: 0 24px;

  &:nth-child(even) {
    background-color: var(--grey-light);
  }

  &:hover {
    background-color: var(--secondary-super-light);

   .RecurringCosts-costs-row-service, .RecurringCosts-costs-row-cost {
      color: var(--secondary-medium-light);
    }
  }

  .RecurringCosts-costs-row-service {
    flex: 1;
    color: var(--grey-super-dark);
  }

  .RecurringCosts-costs-row-cost {
    color: var(--grey-super-dark);
  }
}
