.Select {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--grey-super-dark);
  height: 28px;

  &.Select-open {
    outline: none;
  }

  label {
    color: var(--grey-super-dark);
    cursor: pointer;
  }

  .Select-current {
    padding-left: 10px;
  }

  .Select-icon {
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:hover {
    color: var(--secondary-medium-light);
  }

  .Select-options {
    display: none;
    background-color: var(--white);
    box-shadow: 0px 5px 10px var(--shadow);
    position: absolute;
    top: 28px;
    right: 0;
    color: var(--grey-dark);

    &.Select-options-open {
      display: initial;
    }

    .Select-option {
      height: 40px;
      padding: 0 28px;
      display: flex;
      align-items: center;

      &.Select-option-active {
        color: var(--secondary-medium-light);
        background-color: unset;
        font-weight: 700;
      }

      &.Select-option-hover {
        background-color: var(--secondary-super-light);
        color: var(--secondary-medium-light);
      }
    }
  }
}
