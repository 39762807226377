.Button {
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;

  &.Button-strech {
    width: 100%;
  }

  &.Button-primary {
    height: 40px;
    padding: 0 16px;
    border-radius: 4px;
    background-color: var(--primary);
    color: var(--white);
    font-size: 14px;
    font-weight: 600;
    box-shadow: 0px 8px 10px var(--shadow);

    &:hover {
      background-color: var(--primary-medium-light);
      box-shadow: 0px 12px 10px var(--shadow);
    }
  }

  &.Button-primary-off {
    height: 40px;
    padding: 0 16px;
    border-radius: 4px;
    
    background-color: var(--secondary-super-light);
    color: var(--grey-dark);
    font-size: 14px;
    font-weight: 600;
    box-shadow: 0px 8px 10px var(--shadow);

    &:hover {
      background-color: var(--secondary-medium-light);
      box-shadow: 0px 12px 10px var(--shadow);
      color: var(--white);
    }
  }

  &.Button-secondary {
    height: 32px;
    padding: 0 16px;
    color: var(--secondary);
    font-size: 14px;
    font-weight: 600;

    &:hover {
      color: var(--secondary-medium-light);
    }
  }

  &.Button-secondary-text {
    height: 32px;
    padding: 0 16px;
    color: var(--grey-dark);
    font-size: 12px;
    font-weight: 400;

    &:hover {
      color: var(--grey-super-dark);
    }
  }

  &:disabled {
    cursor: default;
    background-color: var(--primary-medium-light);
    &.Button-primary:hover {
      background-color: var(--primary-medium-light);
    }
  }
}
