.ErrorPage {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  > :first-child {
    margin-bottom: 24px;
  }

  .ErrorPage-help {
    margin-top: 16px;
    display: flex;

    .ErrorPage-back {
      cursor: pointer;
    }
  }
}
